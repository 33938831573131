import { apiSlice } from "../api.service";

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getListings: builder.query({
      query: (params) => ({
        url: "/admin/listings",
        method: "GET",
        params: {
          limit: params?.limit || 10,
          skip: params?.skip || 0,
          approvalStatus: params?.approvalStatus,
          listingType: params?.listingType,
          city: params?.city,
        },
      }),
      transformResponse: (response) => {
        if (Array.isArray(response)) {
          return { data: response, total: response.length };
        }
        return response;
      },
      providesTags: (result = { data: [] }) => [
        ...result.data.map(({ _id }) => ({ type: "Listing-Admin", id: _id })),
        { type: "Listing-Admin", id: "LIST" },
      ],
    }),
    deleteListing: builder.mutation({
      query: (listingId) => ({
        url: `/admin/listings/${listingId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, listingId) => [
        { type: "Listing-Admin", id: listingId },
        { type: "Listing-Admin", id: "LIST" },
      ],
    }),

    getListing: builder.query({
      query: (listingId) => ({
        url: `/admin/listings/${listingId}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [
        { type: "Listing-Admin", id: arg },
      ],
    }),

    processListing: builder.mutation({
      query: ({ listingId, action }) => ({
        url: `/admin/listings/${listingId}/process`,
        method: "PATCH",
        body: { action },
      }),
      invalidatesTags: (result, error, { listingId }) => [
        { type: "Listing-Admin", id: listingId },
        { type: "Listing-Admin", id: "LIST" },
      ],
      async onQueryStarted({ listingId }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedListing } = await queryFulfilled;

          // Update individual listing cache
          dispatch(
            apiSlice.util.updateQueryData("getListing", listingId, (draft) => {
              Object.assign(draft, updatedListing);
            })
          );

          // Update listing in list cache
          dispatch(
            apiSlice.util.updateQueryData("getListings", undefined, (draft) => {
              const listing = draft.data?.find((l) => l._id === listingId);
              if (listing) {
                Object.assign(listing, updatedListing);
              }
            })
          );
        } catch (err) {
          console.error("Error processing listing:", err);
        }
      },
    }),
  }),
});

export const {
  useGetListingsQuery,
  useGetListingQuery,
  useProcessListingMutation,
  useDeleteListingMutation,
} = extendedApi;
