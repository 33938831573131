import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Grow from "@mui/material/Grow";
import { HiShoppingBag, HiPhone, HiMapPin } from "react-icons/hi2";
import LinearProgress from "@mui/material/LinearProgress";
import { useGetBookingQuery } from "../../../../api/endpoints/booking";
import Cart from "./cart";
import ProofOfPayment from "./proofOfPayment";

const getDisplayName = (profile) => {
  if (!profile) return "";

  switch (profile.type) {
    case "personal":
      return profile.personal?.firstName && profile.personal?.lastName
        ? `${profile.personal.firstName} ${profile.personal.lastName}`
        : "";

    case "venue":
      return profile.venue?.venueName || "";

    case "entertainer":
      return profile.entertainer?.entertainerType === "individual"
        ? `${profile.entertainer.individual?.firstName || ""} ${
            profile.entertainer.individual?.lastName || ""
          }`
        : profile.entertainer?.business?.businessName || "";

    default:
      return "";
  }
};

export default function Booking({ open, setOpen, id }) {
  const {
    data: booking = null,
    isLoading,
    isFetching,
  } = useGetBookingQuery(id);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <Stack sx={{}} spacing={2} alignItems="center" direction="row">
          <Typography sx={{ flex: 1 }}>
            Booking Details {"("}
            {booking ? booking.bookingNumber : "loading"}
            {")"}
          </Typography>
          <Button onClick={() => setOpen(false)}>Done</Button>
        </Stack>
      </DialogTitle>

      <Grow in={isLoading || isFetching}>
        <LinearProgress />
      </Grow>
      <Divider />

      <DialogContent>
        {booking && (
          <Grow in={!isLoading}>
            <div>
              {/* Client Information Section */}
              <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                Client Information
              </Typography>
              <Stack sx={{ mt: 1 }}>
                <Stack>
                  <Typography>
                    <b>{getDisplayName(booking.clientProfile)}</b>
                  </Typography>
                  <Chip
                    size="small"
                    color="info"
                    sx={{ alignSelf: "flex-start" }}
                    label={
                      booking.clientProfile.type.charAt(0).toUpperCase() +
                      booking.clientProfile.type.slice(1).replace("_", " ")
                    }
                  />
                </Stack>
              </Stack>

              <Stack sx={{ mt: 2 }} direction="row">
                <HiPhone style={{ marginTop: 4 }} />
                <Stack sx={{ ml: 1 }}>
                  <Typography>Phone: {booking.clientProfile.phone}</Typography>
                  <Typography variant="caption">
                    Email: {booking.clientProfile.user.email}
                  </Typography>
                </Stack>
              </Stack>
              <Stack sx={{ mt: 2 }} direction="row">
                <HiMapPin style={{ marginTop: 4 }} />
                <Stack sx={{ ml: 1 }}>
                  <Typography>{booking.location.address}</Typography>
                </Stack>
              </Stack>

              <Divider sx={{ mt: 2, mb: 2, opacity: 0.6 }} />

              {/* Provider Information Section */}
              <Typography variant="h6" sx={{ mb: 1 }}>
                Provider Information
              </Typography>
              <Stack sx={{ mt: 1 }}>
                <Stack>
                  <Typography>
                    <b>{getDisplayName(booking.providerProfile)}</b>
                  </Typography>
                  <Chip
                    size="small"
                    color="secondary"
                    sx={{ alignSelf: "flex-start" }}
                    label={
                      booking.providerProfile.type.charAt(0).toUpperCase() +
                      booking.providerProfile.type.slice(1).replace("_", " ")
                    }
                  />
                </Stack>
              </Stack>

              <Stack sx={{ mt: 2 }} direction="row">
                <HiPhone style={{ marginTop: 4 }} />
                <Stack sx={{ ml: 1 }}>
                  <Typography>
                    Phone: {booking.providerProfile.phone}
                  </Typography>
                  <Typography variant="caption">
                    Email: {booking.providerProfile.user.email}
                  </Typography>
                </Stack>
              </Stack>

              <Divider sx={{ mt: 2, mb: 2, opacity: 0.6 }} />

              {/* Listing Details Section */}
              <Typography variant="h6" sx={{ mb: 1 }}>
                Listing Details
              </Typography>
              <Stack sx={{ mt: 1 }}>
                <Typography variant="h6">{booking.listing.title}</Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mt: 1 }}
                >
                  {booking.listing.description}
                </Typography>
                <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                  <Chip
                    label={booking.listing.category?.title.toUpperCase() || ""}
                    color="success"
                  />
                  {booking.listing.rentalMode && (
                    <Chip label={booking.listing.rentalMode} color="info" />
                  )}
                </Stack>
              </Stack>

              <Divider sx={{ mt: 2, mb: 2, opacity: 0.6 }} />

              {/* Booking Details Section */}
              <Stack sx={{ alignItems: "center", mt: 2 }} direction="row">
                <HiShoppingBag />
                <Typography sx={{ ml: 2 }}>
                  Booked dates{"(" + booking.acceptedDates.length + ")"}
                </Typography>
              </Stack>

              {booking?.proofOfPayment?.uploaded && (
                <ProofOfPayment booking={booking} />
              )}

              <Cart booking={booking} />
            </div>
          </Grow>
        )}
      </DialogContent>
    </Dialog>
  );
}
