import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { HiClock, HiCheckCircle } from "react-icons/hi2";

import moment from "moment";
import React from "react";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Cart({ booking }) {
  const total = React.useMemo(() => {
    const baseRate = booking.ratePerHour
      ? booking.ratePerHour
      : booking.ratePerDay;
    const transportCost = booking.transportPerDate || 0;
    const hours = booking.numHours || 1;
    const datesCount = (booking.acceptedDates || []).length;

    return booking.ratePerHour
      ? (baseRate * hours + transportCost) * datesCount
      : (baseRate + transportCost) * datesCount;
  }, [booking]);

  return (
    <Stack sx={{ mt: 2 }}>
      <Stack sx={{ background: "#353535", p: 2, borderRadius: 2 }}>
        <Stack>
          <Typography>
            <b>{booking.rentalMode ? "Equipment" : "Entertainment"}</b>
          </Typography>
          <Stack sx={{ mt: 1 }}>
            {booking.acceptedDates.map((dateStr) => {
              const date = moment(dateStr).toDate();
              const subtotal = booking.ratePerHour
                ? booking.ratePerHour * (booking.numHours || 1)
                : booking.ratePerDay;
              const commission = (booking.commissionApplied / 100) * subtotal;
              const transport = booking.transportPerDate || 0;

              return (
                <Stack key={dateStr} sx={{ mb: 1 }}>
                  <Stack sx={{ alignItems: "center" }} direction="row">
                    {new Date() <= date ? (
                      <HiClock />
                    ) : (
                      <HiCheckCircle color="#00AA33" />
                    )}
                    <Typography sx={{ ml: 1 }}>
                      {moment(date).format("D MMM YYYY")} -{" "}
                      {booking.numHours
                        ? `${booking.numHours} hour(s)`
                        : "1 day"}
                    </Typography>
                  </Stack>
                  <Typography variant="subtitle2">
                    - Service charge: ${numberWithCommas(subtotal.toFixed(2))}
                  </Typography>
                  <Typography variant="subtitle2">
                    - Commission: ${numberWithCommas(commission.toFixed(2))}
                  </Typography>
                  <Typography variant="subtitle2">
                    - Payout: $
                    {numberWithCommas((subtotal - commission).toFixed(2))}
                  </Typography>
                  <Typography variant="subtitle2">
                    - Transport: ${numberWithCommas(transport.toFixed(2))}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
