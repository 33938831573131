import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Grow from "@mui/material/Grow";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import {
  HiPhone,
  HiCheckBadge,
  HiXMark,
  HiCurrencyDollar,
  HiCalendar,
} from "react-icons/hi2";
import Chip from "@mui/material/Chip";
import LinearProgress from "@mui/material/LinearProgress";
import { constants } from "../../../../config/constants";
import {
  useGetListingQuery,
  useProcessListingMutation,
  useDeleteListingMutation,
} from "../../../../api/endpoints/listing";
import { CircularProgress } from "@mui/material";
import { Delete } from "@mui/icons-material";

const getDisplayName = (profile) => {
  if (!profile) return "";

  switch (profile.type) {
    case "personal":
      return profile.personal?.firstName && profile.personal?.lastName
        ? `${profile.personal.firstName} ${profile.personal.lastName}`
        : "";

    case "venue":
      return profile.venue?.venueName || "";

    case "entertainer":
      return profile.entertainer?.entertainerType === "individual"
        ? `${profile.entertainer.individual?.firstName || ""} ${
            profile.entertainer.individual?.lastName || ""
          }`
        : profile.entertainer?.business?.businessName || "";

    case "event_promoter":
      return profile.eventPromoter?.promoterType === "individual"
        ? `${profile.eventPromoter.individual?.firstName || ""} ${
            profile.eventPromoter.individual?.lastName || ""
          }`
        : profile.eventPromoter?.business?.businessName || "";

    case "corporate":
      return profile.corporate?.businessName || "";

    default:
      return "";
  }
};

function formatCurrency(amount) {
  if (!amount) return "$0.00";
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  }).format(amount);
}

export default function ListingModal({ open, setOpen, id }) {
  const {
    data: listing = null,
    isLoading,
    isFetching,
  } = useGetListingQuery(id);
  const [processListing, { isLoading: isProcessing }] =
    useProcessListingMutation();
  const [deleteListing, { isLoading: isDeleting }] = useDeleteListingMutation();

  const handleProcess = async (action) => {
    try {
      await processListing({
        listingId: id,
        action,
      }).unwrap();
      setOpen(false);
    } catch (error) {
      alert("Failed to process listing");
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this listing?")) {
      try {
        await deleteListing(id).unwrap();
        setOpen(false);
      } catch (error) {
        alert("Failed to delete listing");
      }
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Listing Details</DialogTitle>

      <Grow in={isLoading || isFetching}>
        <LinearProgress />
      </Grow>
      <Divider />

      <DialogContent sx={{ height: "60vh", overflowY: "auto" }}>
        {listing && (
          <Grow in={!isLoading}>
            <div>
              <Stack sx={{ mt: 1 }}>
                <Typography sx={{ mb: 1, color: "#CECECE" }}>
                  <b>About Listing</b>
                </Typography>
                <Stack>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="h6">{listing.title}</Typography>
                    <Chip
                      size="small"
                      color="primary"
                      label={listing.listingType}
                    />
                    <Chip size="small" color="secondary" label={listing.city} />
                  </Stack>

                  <Typography variant="body2" sx={{ mt: 1 }}>
                    {listing.description}
                  </Typography>

                  <Stack sx={{ mt: 2 }} direction="row" alignItems="center">
                    <HiCurrencyDollar />
                    <Typography sx={{ ml: 1 }}>
                      {listing.listingType === "EQUIPMENT" ? (
                        `${formatCurrency(listing.rentalRate)} per ${
                          listing.rentalMode
                        }`
                      ) : (
                        <>
                          Single Date: {formatCurrency(listing.singleDateRate)}
                          <br />
                          Multiple Dates:{" "}
                          {formatCurrency(listing.multiDateRate)}
                        </>
                      )}
                    </Typography>
                  </Stack>

                  {listing.category && (
                    <Stack sx={{ mt: 2 }} direction="row" alignItems="center">
                      <Typography sx={{ ml: 1 }}>
                        Category: {listing.category.title}
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              </Stack>

              <Divider sx={{ mt: 2, mb: 2, opacity: 0.6 }} />

              {listing.gallery && listing.gallery.length > 0 && (
                <>
                  <Typography sx={{ mb: 2 }}>
                    <b>Gallery</b>
                  </Typography>
                  <Stack direction="row" sx={{ flexWrap: "wrap", gap: 2 }}>
                    {listing.gallery.map((item, index) => (
                      <Zoom>
                        <img
                          key={index}
                          style={{ width: "150px", borderRadius: "4px" }}
                          src={`${constants.apiUrl}/files/${item}`}
                          alt={`listing-${index + 1}`}
                        />
                      </Zoom>
                    ))}
                  </Stack>
                  <Divider sx={{ mt: 2, mb: 2, opacity: 0.6 }} />
                </>
              )}

              <Typography sx={{ mb: 1, color: "#CECECE" }}>
                <b>Provider Details</b>
              </Typography>

              <Stack sx={{ mt: 1 }}>
                <Stack>
                  <Typography variant="h6">
                    {getDisplayName(listing.profile)}
                  </Typography>

                  <Chip
                    size="small"
                    color="info"
                    sx={{ alignSelf: "start", mt: 1 }}
                    label={`${listing.profile?.type
                      ?.charAt(0)
                      .toUpperCase()}${listing.profile?.type?.slice(
                      1
                    )} Account`}
                  />
                </Stack>

                <Stack sx={{ mt: 2 }} direction="row" alignItems="center">
                  <HiPhone />
                  <Stack sx={{ ml: 1 }}>
                    <Typography>{listing.profile?.phone}</Typography>
                    <Typography variant="caption">
                      {listing.user?.email}
                    </Typography>
                  </Stack>
                </Stack>

                {listing.datesUnavailable?.length > 0 && (
                  <Stack sx={{ mt: 2 }} direction="row" alignItems="center">
                    <HiCalendar />
                    <Typography sx={{ ml: 1 }}>
                      {listing.datesUnavailable.length} unavailable dates
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </div>
          </Grow>
        )}
      </DialogContent>

      <Divider />
      <Grow in={isProcessing || isDeleting}>
        <LinearProgress />
      </Grow>

      <DialogActions>
        {listing && (
          <Button
            disabled={isDeleting}
            onClick={handleDelete}
            startIcon={isDeleting ? <CircularProgress size={16} /> : <Delete />}
            color="error"
          >
            Delete
          </Button>
        )}
        {listing && listing.approvalStatus === "pending" && (
          <>
            <Button
              disabled={isLoading || isProcessing}
              onClick={() => handleProcess("approve")}
              startIcon={<HiCheckBadge />}
              color="success"
            >
              Approve
            </Button>
            <Button
              disabled={isLoading || isProcessing}
              onClick={() => handleProcess("reject")}
              startIcon={<HiXMark />}
              color="error"
            >
              Reject
            </Button>
          </>
        )}
        <Button onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
