import Stack from "@mui/material/Stack";
import * as React from "react";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import { ButtonBase, Chip } from "@mui/material";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const getDisplayName = (profile) => {
  if (!profile) return "";

  switch (profile.type) {
    case "personal":
      return profile.personal?.firstName && profile.personal?.lastName
        ? `${profile.personal.firstName} ${profile.personal.lastName}`
        : "";

    case "venue":
      return profile.venue?.venueName || "";

    case "entertainer":
      return profile.entertainer?.entertainerType === "individual"
        ? `${profile.entertainer.individual?.firstName || ""} ${
            profile.entertainer.individual?.lastName || ""
          }`
        : profile.entertainer?.business?.businessName || "";

    case "corporate":
      return profile.corporate?.businessName || "";

    case "event_promoter":
      return profile.eventPromoter?.promoterType === "individual"
        ? `${profile.eventPromoter.individual?.firstName || ""} ${
            profile.eventPromoter.individual?.lastName || ""
          }`
        : profile.eventPromoter?.business?.businessName || "";

    default:
      return "";
  }
};

export default function Row({
  booking,
  index,
  length,
  setOpen,
  setBookingId,
  type,
}) {
  const checked = true;

  const clientName = React.useMemo(
    () => getDisplayName(booking?.clientProfile || {}),
    [booking.clientProfile]
  );

  // Calculate total and commission based on rental mode
  const total = React.useMemo(() => {
    const baseRate = booking?.ratePerHour
      ? booking.ratePerHour
      : booking.ratePerDay;

    const transportCost = booking?.transportPerDate || 0;
    const hours = booking.numHours || 1;
    const datesCount = (booking?.acceptedDates || []).length;

    return booking?.ratePerHour
      ? (baseRate * hours + transportCost) * datesCount
      : (baseRate + transportCost) * datesCount;
  }, [booking]);

  const commission = React.useMemo(() => {
    const baseRate = booking.ratePerHour
      ? booking.ratePerHour
      : booking.ratePerDay;
    const hours = booking.numHours || 1;
    const datesCount = (booking.acceptedDates || []).length;

    return (
      (booking.ratePerHour
        ? baseRate * hours * datesCount
        : baseRate * datesCount) *
      (booking.commissionApplied / 100)
    );
  }, [booking]);

  return (
    <Fade timeout={2000} in={checked}>
      <ButtonBase
        onClick={() => {
          setBookingId(booking._id);
          setOpen(true);
        }}
        sx={{
          mt: 1,
          textAlign: "left",
        }}
      >
        <Stack
          sx={{
            borderRadius: 0,
            background: "#1B1B1B",
            borderWidth: 0,
            borderLeftWidth: 1,
            borderStyle: "solid",
            alignItems: "center",
            flex: 1,
            p: 2,
          }}
          direction="row"
        >
          <Stack sx={{ flex: 1, pr: 2 }}>
            <Typography
              sx={{
                cursor: "pointer",
                "&:hover": {
                  textDecorationLine: "underline",
                },
              }}
            >
              {booking.bookingNumber} <span> - </span>
              {index + 1} of {length}
            </Typography>
            <Typography variant="caption">{clientName}</Typography>
          </Stack>
          <Stack sx={{ flex: 1, pr: 2 }}>
            <Typography>${numberWithCommas(total.toFixed(2))}</Typography>
          </Stack>
          {type !== "unpaid" ? (
            <Stack sx={{ flex: 1, pr: 2 }}>
              <Typography>
                ${numberWithCommas(commission.toFixed(2))}
              </Typography>
            </Stack>
          ) : (
            <Stack sx={{ flex: 1, pr: 2 }}>
              {booking.proofOfPayment?.uploaded ? (
                <Chip
                  sx={{ alignSelf: "flex-start" }}
                  color="warning"
                  label="POP Uploaded"
                />
              ) : (
                <Chip
                  sx={{ alignSelf: "flex-start" }}
                  label="Pending Payment"
                />
              )}
            </Stack>
          )}
          <Stack
            sx={{
              flex: 1,
              pr: 2,
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
              },
            }}
          >
            <Typography>
              {booking.rentalMode ? "Equipment" : "Entertainment"}
            </Typography>
          </Stack>
        </Stack>
      </ButtonBase>
    </Fade>
  );
}
