import { apiSlice } from "../api.service";

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get all profiles with optional filters
    getProfiles: builder.query({
      query: (filters) => ({
        url: "/admin/profiles",
        method: "GET",
        params: filters,
      }),
      providesTags: (result = [], error, arg) => [
        "Profile-Admin",
        ...result.map(({ _id }) => ({ type: "Profile-Admin", id: _id })),
        { type: "Profile-Admin", id: "LIST" },
      ],
    }),

    // Get single profile
    getProfile: builder.query({
      query: (profileId) => ({
        url: `/admin/profiles/${profileId}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [
        { type: "Profile-Admin", id: arg },
      ],
    }),

    verifyProfile: builder.mutation({
      query: ({ profileId, action }) => ({
        url: `/admin/profiles/${profileId}/verify`,
        method: "PATCH",
        body: { action },
      }),
      async onQueryStarted(
        { profileId, action },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data: updatedProfile } = await queryFulfilled;

          // Update the profile in the list view
          dispatch(
            apiSlice.util.updateQueryData("getProfiles", undefined, (draft) => {
              const profile = draft.find((p) => p._id === profileId);
              if (profile) {
                profile.verificationStatus =
                  action === "approve" ? "Verified" : "Rejected";
              }
            })
          );

          // Update the single profile view if it exists in cache
          dispatch(
            apiSlice.util.updateQueryData("getProfile", profileId, (draft) => {
              Object.assign(draft, updatedProfile);
            })
          );
        } catch {
          // If the mutation fails, the cache will be rolled back automatically
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProfilesQuery,
  useGetProfileQuery,
  useVerifyProfileMutation,
} = extendedApi;
