import { apiSlice } from "../api.service";

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getConfiguration: builder.query({
      query: () => ({
        url: "/admin/configuration",
        method: "GET",
      }),
      providesTags: ["Configuration"],
    }),
    updateConfiguration: builder.mutation({
      query: (configData) => ({
        url: "/admin/configuration",
        method: "PATCH",
        body: configData,
      }),
      invalidatesTags: ["Configuration"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetConfigurationQuery, useUpdateConfigurationMutation } =
  extendedApi;
