/* eslint-disable jsx-a11y/img-redundant-alt */
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Grow from "@mui/material/Grow";
import { HiPhone, HiMapPin, HiCalendar, HiClock } from "react-icons/hi2";
import Chip from "@mui/material/Chip";
import LinearProgress from "@mui/material/LinearProgress";
import Alert from "@mui/material/Alert";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import * as React from "react";
import {
  useGetEventQuery,
  useProcessEventMutation,
} from "../../../../api/endpoints/event";
import { constants } from "../../../../config/constants";

export default function EventModal({ open, setOpen, _id }) {
  const {
    data: event,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useGetEventQuery(_id);

  console.log(event);

  const [processEvent, { isLoading: isProcessing }] = useProcessEventMutation();

  const handleProcess = async (action) => {
    try {
      await processEvent({ eventId: _id, action }).unwrap();
      refetch();
    } catch (error) {
      console.error("Failed to process event:", error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  if (isError) {
    return (
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <Alert severity="error">
            Failed to load event details. Please try again.
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        Event Details
        {event?.approvalStatus && (
          <Chip
            size="small"
            color={
              event.approvalStatus === "approved"
                ? "success"
                : event.approvalStatus === "rejected"
                ? "error"
                : "warning"
            }
            label={event.approvalStatus}
            sx={{ ml: 1 }}
          />
        )}
      </DialogTitle>

      <Grow in={isLoading || isFetching}>
        <LinearProgress />
      </Grow>

      <Divider />

      <DialogContent sx={{ height: "60vh" }}>
        {event && (
          <Grow in={!isLoading}>
            <div>
              <Stack sx={{ mt: 1 }}>
                <Typography sx={{ mb: 1, color: "#CECECE" }}>
                  <b>About Event</b>
                </Typography>

                <Stack spacing={2}>
                  <Typography variant="h6">{event.title}</Typography>

                  <Typography>
                    <b>Description:</b> {event.description}
                  </Typography>

                  <Stack direction="row" spacing={1} alignItems="center">
                    <HiCalendar />
                    <Typography>{formatDate(event.date)}</Typography>
                  </Stack>

                  <Stack direction="row" spacing={1} alignItems="center">
                    <HiClock />
                    <Typography>{formatTime(event.date)}</Typography>
                  </Stack>

                  {(event.location || event.locationEmbed) && (
                    <Stack direction="row" spacing={1}>
                      <HiMapPin style={{ marginTop: 4 }} />
                      <Stack>
                        <Typography>
                          {event.location?.address ||
                            event.locationEmbed?.address}
                        </Typography>
                        {(event.location?.venueNumber ||
                          event.locationEmbed?.venueNumber) && (
                          <Typography variant="body2" color="textSecondary">
                            Venue Number:{" "}
                            {event.location?.venueNumber ||
                              event.locationEmbed?.venueNumber}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              </Stack>

              <Divider sx={{ my: 2 }} />
              <Typography sx={{ mb: 1, color: "#CECECE" }}>
                <b>Listed By</b>
              </Typography>

              {event.userProfile && (
                <>
                  <Stack sx={{ mt: 1 }} spacing={1}>
                    <Stack>
                      <Typography variant="body1" fontWeight={500}>
                        {event.userProfile.type === "venue" &&
                          event.userProfile.venue?.venueName}
                        {event.userProfile.type === "event_promoter" &&
                          (event.userProfile.eventPromoter?.promoterType ===
                          "business"
                            ? event.userProfile.eventPromoter.business
                                ?.businessName
                            : `${event.userProfile.eventPromoter?.individual?.firstName} ${event.userProfile.eventPromoter?.individual?.lastName}`)}
                      </Typography>
                    </Stack>

                    <Chip
                      size="small"
                      color="info"
                      sx={{ alignSelf: "start" }}
                      label={
                        event.userProfile.type === "venue"
                          ? "Venue"
                          : "Event Promoter"
                      }
                    />

                    {event.userProfile.representative && (
                      <Typography>
                        Representative:{" "}
                        {event.userProfile.representative.firstName}{" "}
                        {event.userProfile.representative.lastName}
                      </Typography>
                    )}

                    {event.userProfile.phone && (
                      <Stack direction="row" spacing={1} alignItems="center">
                        <HiPhone />
                        <Typography>{event.userProfile.phone}</Typography>
                      </Stack>
                    )}

                    {event.userProfile.verificationStatus && (
                      <Chip
                        size="small"
                        color={
                          event.userProfile.verificationStatus === "Verified"
                            ? "success"
                            : "warning"
                        }
                        sx={{ alignSelf: "start" }}
                        label={`${event.userProfile.verificationStatus}`}
                      />
                    )}
                  </Stack>
                </>
              )}

              {event.tickets && event.tickets.length > 0 && (
                <>
                  <Divider sx={{ my: 2 }} />
                  <Typography sx={{ mb: 1, color: "#CECECE" }}>
                    <b>Tickets</b>
                  </Typography>

                  {event.tickets.map((ticket, index) => (
                    <Stack
                      key={ticket._id || index}
                      sx={{
                        borderRadius: 2,
                        p: 2,
                        bgcolor: "background.paper",
                        mb: 1,
                        border: 1,
                        borderColor: "divider",
                      }}
                    >
                      <Stack spacing={2}>
                        <Stack direction="row" justifyContent="space-between">
                          <div>
                            <Typography variant="caption" color="textSecondary">
                              Ticket name
                            </Typography>
                            <Typography>{ticket.name}</Typography>
                          </div>
                          <div>
                            <Typography variant="caption" color="textSecondary">
                              Price
                            </Typography>
                            <Typography>
                              ${Number(ticket.cost).toFixed(2)}
                            </Typography>
                          </div>
                        </Stack>

                        <Stack direction="row" justifyContent="space-between">
                          <div>
                            <Typography variant="caption" color="textSecondary">
                              Available
                            </Typography>
                            <Typography>
                              {ticket.quantity - (ticket.sold || 0)}/
                              {ticket.quantity}
                            </Typography>
                          </div>
                          <div>
                            <Typography variant="caption" color="textSecondary">
                              Total Sales
                            </Typography>
                            <Typography>
                              {ticket.sold || 0} | $
                              {(
                                (ticket.cost || 0) * (ticket.sold || 0)
                              ).toFixed(2)}
                            </Typography>
                          </div>
                        </Stack>
                      </Stack>
                    </Stack>
                  ))}
                </>
              )}

              {event.gallery?.length > 0 && (
                <>
                  <Divider sx={{ my: 2 }} />
                  <Typography sx={{ mb: 1 }}>
                    <b>Gallery</b>
                  </Typography>
                  <Stack
                    direction="row"
                    sx={{
                      flexWrap: "wrap",
                      gap: 2,
                    }}
                  >
                    {event.gallery.map((item, index) => (
                      <Zoom key={index}>
                        <img
                          style={{
                            width: "150px",
                            height: "150px",
                            objectFit: "cover",
                            borderRadius: 8,
                          }}
                          src={`${constants.apiUrl}/files/${item}`}
                          alt={`Event image ${index + 1}`}
                        />
                      </Zoom>
                    ))}
                  </Stack>
                </>
              )}
            </div>
          </Grow>
        )}
      </DialogContent>

      <Divider />

      <DialogActions>
        {event?.approvalStatus === "pending" && (
          <>
            <Button
              disabled={isProcessing}
              onClick={() => handleProcess("reject")}
              color="error"
            >
              Reject
            </Button>
            <Button
              disabled={isProcessing}
              onClick={() => handleProcess("approve")}
              color="success"
            >
              Approve
            </Button>
          </>
        )}
        <Button onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
