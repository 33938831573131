import { apiSlice } from "../api.service";

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPayoutSummaries: builder.query({
      query: () => ({
        url: "/admin/payouts/summaries",
        method: "GET",
      }),
      providesTags: (result) => {
        if (!result) return [{ type: "PayoutSummary", id: "LIST" }];

        const eventTags =
          result.events?.map((event) => ({
            type: "PayoutSummary",
            id: `event-${event.eventId}`,
          })) || [];

        const bookingTags =
          result.bookings?.map((booking) => ({
            type: "PayoutSummary",
            id: `booking-${booking.bookingNumber}`,
          })) || [];

        return [
          { type: "PayoutSummary", id: "LIST" },
          ...eventTags,
          ...bookingTags,
        ];
      },
    }),
    processPayout: builder.mutation({
      query: (payload) => ({
        url: "/admin/payouts/process",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [{ type: "PayoutSummary", id: "LIST" }],
    }),
  }),
  overrideExisting: false,
});

export const { useGetPayoutSummariesQuery, useProcessPayoutMutation } =
  extendedApi;
