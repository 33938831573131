import { apiSlice } from "../api.service";

let params = { limit: 10, skip: 0, type: "unpaid" };

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBookings: builder.query({
      query: (options) => {
        params = options;
        return {
          url: "/admin/bookings",
          method: "GET",
          params: options,
        };
      },
      // Transform response to handle both paginated and non-paginated cases
      transformResponse: (response) => {
        if (Array.isArray(response)) {
          return { data: response, total: response.length };
        }
        return response;
      },
      providesTags: (result = { data: [] }) => [
        ...result.data.map(({ _id }) => ({ type: "Booking-Admin", id: _id })),
        { type: "Booking-Admin", id: "LIST" },
      ],
    }),

    getBooking: builder.query({
      query: (bookingId) => ({
        url: `/admin/bookings/${bookingId}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [
        { type: "Booking-Admin", id: arg },
      ],
    }),

    processBookingPop: builder.mutation({
      query: ({ bookingId, approved }) => ({
        url: `/admin/bookings/${bookingId}/process-pop`,
        method: "PATCH",
        body: { approved },
      }),
      invalidatesTags: (result, error, { bookingId }) => [
        { type: "Booking-Admin", id: "LIST" },
      ],
      async onQueryStarted(
        { bookingId, approved },
        { dispatch, queryFulfilled }
      ) {
        try {
          // Wait for the server response
          const { data } = await queryFulfilled;

          // Only update caches after successful server response
          dispatch(
            extendedApi.util.updateQueryData(
              "getBooking",
              bookingId,
              (draft) => {
                if (draft) {
                  Object.assign(draft, data);
                }
              }
            )
          );

          dispatch(
            extendedApi.util.updateQueryData("getBookings", params, (draft) => {
              const bookingIndex = draft.data.findIndex(
                (b) => b._id === bookingId
              );
              if (bookingIndex !== -1) {
                draft.data[bookingIndex] = {
                  ...draft.data[bookingIndex],
                  ...data,
                };
              }
            })
          );
        } catch (err) {
          console.error("Error processing POP:", err);
        }
      },
    }),

    processBookingPayout: builder.mutation({
      query: ({ bookingId, paidOut }) => ({
        url: `/admin/bookings/${bookingId}/payout`,
        method: "PATCH",
        body: { paidOut },
      }),
      async onQueryStarted(
        { bookingId, paidOut },
        { dispatch, queryFulfilled }
      ) {
        // Optimistic update for list view
        const patchListResult = dispatch(
          apiSlice.util.updateQueryData("getAdminBookings", params, (draft) => {
            const booking = draft.data?.find((b) => b._id === bookingId);
            if (booking) {
              booking.paidOut = paidOut;
            }
          })
        );

        // Optimistic update for single view
        const patchSingleResult = dispatch(
          apiSlice.util.updateQueryData(
            "getAdminBooking",
            bookingId,
            (draft) => {
              if (draft) {
                draft.paidOut = paidOut;
              }
            }
          )
        );

        try {
          const { data: updatedBooking } = await queryFulfilled;

          // Update cache with actual response
          dispatch(
            apiSlice.util.updateQueryData(
              "getAdminBookings",
              params,
              (draft) => {
                const booking = draft.data?.find((b) => b._id === bookingId);
                if (booking) {
                  Object.assign(booking, updatedBooking);
                }
              }
            )
          );

          dispatch(
            apiSlice.util.updateQueryData(
              "getAdminBooking",
              bookingId,
              (draft) => {
                Object.assign(draft, updatedBooking);
              }
            )
          );
        } catch {
          // Undo optimistic updates on error
          patchListResult.undo();
          patchSingleResult.undo();
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetBookingQuery,
  useGetBookingsQuery,
  useProcessBookingPopMutation,
  useProcessBookingPayoutMutation,
} = extendedApi;
