import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Grow from "@mui/material/Grow";
import { HiPhone, HiCheckBadge } from "react-icons/hi2";
import Chip from "@mui/material/Chip";
import LinearProgress from "@mui/material/LinearProgress";
import { constants } from "../../../../config/constants";
import {
  useGetProfileQuery,
  useVerifyProfileMutation,
} from "../../../../api/endpoints/profile";
import { Warning } from "@mui/icons-material";
export default function Profile({ open, setOpen, id }) {
  const {
    data: profile = null,
    isLoading,
    isFetching,
  } = useGetProfileQuery(id);

  const [verifyProfile, { isLoading: isUpdating }] = useVerifyProfileMutation();

  const updateVerificationStatus = async (action) => {
    try {
      const result = await verifyProfile({ profileId: profile._id, action });
      if ("error" in result) {
        throw new Error("Request failed");
      }
      setOpen(false);
    } catch (e) {
      alert("Sorry, request failed");
    }
  };

  const getDisplayName = () => {
    if (!profile) return "";

    switch (profile.type) {
      case "personal":
        return profile.personal?.firstName && profile.personal?.lastName
          ? `${profile.personal.firstName} ${profile.personal.lastName}`
          : "";

      case "entertainer":
        return profile.entertainer?.entertainerType === "individual"
          ? `${profile.entertainer.individual?.firstName || ""} ${
              profile.entertainer.individual?.lastName || ""
            }`
          : profile.entertainer?.business?.businessName || "";

      case "venue":
        return profile.venue?.venueName || "";

      case "corporate":
        return profile.corporate?.businessName || "";

      case "event_promoter":
        return profile.eventPromoter?.promoterType === "individual"
          ? `${profile.eventPromoter.individual?.firstName || ""} ${
              profile.eventPromoter.individual?.lastName || ""
            }`
          : profile.eventPromoter?.business?.businessName || "";

      default:
        return "";
    }
  };

  // Check if profile needs verification and is not already verified
  const canVerify =
    profile?.requiresVerification &&
    profile?.verificationStatus !== "Verified" &&
    profile?.type !== "personal";

  const getIdentificationInfo = () => {
    if (!profile?.identification) return null;

    return (
      <>
        <Typography sx={{ mt: 2 }}>
          <b>Proof of identity</b>
        </Typography>
        <Typography sx={{ mb: 2 }}>
          {profile.identification.identityType}:{" "}
          {profile.identification.identityNumber}
        </Typography>
        {profile.identification.identityAttachment && (
          <img
            style={{ width: "100%" }}
            src={`${constants.apiUrl}/files/${profile.identification.identityAttachment}`}
            alt="Proof of Identity"
          />
        )}
      </>
    );
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Profile Details</DialogTitle>

      <Grow in={isLoading || isFetching}>
        <LinearProgress />
      </Grow>
      <Divider />

      <DialogContent sx={{ height: "80vh" }}>
        {profile && (
          <Grow in={!isLoading}>
            <div>
              <Stack sx={{ mt: 1 }}>
                <Stack>
                  <Typography>
                    <b>{getDisplayName()}</b>{" "}
                    {profile.verificationStatus === "Verified" && (
                      <HiCheckBadge />
                    )}
                  </Typography>
                </Stack>
                <Chip
                  size="small"
                  color="info"
                  sx={{ alignSelf: "start" }}
                  label={`${
                    profile.type.charAt(0).toUpperCase() +
                    profile.type.slice(1).replace("_", " ")
                  } account`}
                />
                {profile.representative && (
                  <Typography>
                    Representative: {profile.representative.firstName}{" "}
                    {profile.representative.lastName}
                  </Typography>
                )}
              </Stack>

              <Stack sx={{ mt: 2 }} direction="row">
                <HiPhone style={{ marginTop: 4 }} />
                <Stack sx={{ ml: 1 }}>
                  <Typography>{profile.phone}</Typography>
                  <Typography variant="caption">
                    {profile.user?.email}
                  </Typography>
                </Stack>
              </Stack>

              <Divider sx={{ mt: 2, mb: 2, opacity: 0.6 }} />

              {getIdentificationInfo()}
            </div>
          </Grow>
        )}
      </DialogContent>
      <Divider />
      <Grow in={isUpdating}>
        <LinearProgress />
      </Grow>
      <DialogActions>
        {canVerify && (
          <>
            <Button
              onClick={() => updateVerificationStatus("approve")}
              disabled={isLoading || isUpdating}
              startIcon={<HiCheckBadge />}
              color="success"
            >
              Verify
            </Button>
            <Button
              onClick={() => updateVerificationStatus("reject")}
              disabled={isLoading || isUpdating}
              startIcon={<Warning />}
              color="error"
            >
              Reject
            </Button>
          </>
        )}
        <Button onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
