import Stack from "@mui/material/Stack";
import * as React from "react";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import Modal from "./modal/view";
import { ButtonBase } from "@mui/material";

const getDisplayName = (profile) => {
  if (!profile) return "";

  switch (profile.type) {
    case "entertainer":
      return profile.entertainer?.entertainerType === "individual"
        ? `${profile.entertainer.individual?.firstName || ""} ${
            profile.entertainer.individual?.lastName || ""
          }`
        : profile.entertainer?.business?.businessName || "";

    default:
      return "";
  }
};

export default function Row({ listing, index, length }) {
  const [open, setOpen] = React.useState(false);
  const { title, category, profile } = listing;
  console.log(profile);

  return (
    <>
      <Fade timeout={2000} in={true}>
        <ButtonBase
          onClick={() => setOpen(true)}
          sx={{ mt: 1 }}
          key={listing._id}
        >
          <Stack
            sx={{
              borderRadius: 0,
              p: 2,
              background: "#1B1B1B",
              borderWidth: 0,
              borderLeftWidth: 1,
              borderStyle: "solid",
              alignItems: "center",
              flex: 1,
              textAlign: "left",
            }}
            direction="row"
          >
            <Stack sx={{ flex: 1, pr: 2 }}>
              <Typography>{title}</Typography>
              <Typography variant="caption">
                {index + 1} of {length}
              </Typography>
            </Stack>

            <Stack
              sx={{
                flex: 1,
                pr: 2,
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                  lg: "flex",
                },
              }}
            >
              <Typography>{category?.title || ""}</Typography>
            </Stack>

            <Stack sx={{ flex: 1, pr: 2 }}>
              <Typography>{getDisplayName(profile)}</Typography>
            </Stack>

            <Stack
              sx={{
                flex: 1,
                pr: 2,
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                  lg: "flex",
                },
              }}
            >
              <Typography>{profile?.user?.email || ""}</Typography>
            </Stack>
          </Stack>
        </ButtonBase>
      </Fade>

      {open && <Modal open={open} id={listing._id} setOpen={setOpen} />}
    </>
  );
}
