import {
  useGetPayoutSummariesQuery,
  useProcessPayoutMutation,
} from "../../api/endpoints/payouts";

import { useGetProfileQuery } from "../../api/endpoints/profile";

import React, { useState } from "react";
import {
  Stack,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Chip,
  Divider,
  Box,
  styled,
  Alert,
  CircularProgress,
  TableContainer,
  useMediaQuery,
  CardContent,
  Card,
} from "@mui/material";
import { format } from "date-fns";
import { useTheme } from "@emotion/react";

const ProfileInfo = ({ profile }) => {
  if (!profile) return null;

  const renderProfileDetails = () => {
    switch (profile.type) {
      case "event_promoter":
        if (profile.eventPromoter?.promoterType === "individual") {
          return (
            <>
              <Typography>
                {profile.eventPromoter.individual?.firstName}{" "}
                {profile.eventPromoter.individual?.lastName}
              </Typography>
              <Typography color="text.secondary">
                Event Promoter (Individual)
              </Typography>
            </>
          );
        } else {
          return (
            <>
              <Typography>
                {profile.eventPromoter?.business?.businessName}
              </Typography>
              <Typography color="text.secondary">
                Event Promoter (Business)
              </Typography>
            </>
          );
        }

      case "entertainer":
        if (profile.entertainer?.entertainerType === "individual") {
          return (
            <>
              <Typography>
                {profile.entertainer.individual?.firstName}{" "}
                {profile.entertainer.individual?.lastName}
              </Typography>
              <Typography color="text.secondary">
                Entertainer (Individual)
              </Typography>
            </>
          );
        } else {
          return (
            <>
              <Typography>
                {profile.entertainer?.business?.businessName}
              </Typography>
              <Typography color="text.secondary">
                Entertainer (Business)
              </Typography>
            </>
          );
        }

      default:
        return null;
    }
  };

  const renderBankingDetails = () => {
    return (
      <Box mt={1}>
        <Typography variant="subtitle2">Banking Details</Typography>
        <Stack spacing={0.5}>
          <Typography>
            Bank: {profile.bankingDetails?.bankName || "Not available"}
          </Typography>
          <Typography>
            Account Name:{" "}
            {profile.bankingDetails?.accountName || "Not available"}
          </Typography>
          <Typography>
            Acc Number:{" "}
            {profile.bankingDetails?.accountNumber || "Not available"}
          </Typography>
          <Typography>
            Branch: {profile.bankingDetails?.branchCode || "Not available"}
          </Typography>
        </Stack>
      </Box>
    );
  };

  return (
    <Box>
      <Typography variant="h6">Profile Information</Typography>
      {renderProfileDetails()}
      <Typography color="text.secondary" sx={{ mt: 1 }}>
        {profile.phone}
      </Typography>
      {renderBankingDetails()}
      {profile.verificationStatus !== "Verified" && (
        <Alert severity="warning" sx={{ mt: 2 }}>
          Profile verification status: {profile.verificationStatus}
        </Alert>
      )}
    </Box>
  );
};

const PayoutModal = ({ open, onClose, data }) => {
  const [processing, setProcessing] = useState(false);
  const { data: profile, isLoading: profileLoading } = useGetProfileQuery(
    data?.profileId
  );
  const [processPayout] = useProcessPayoutMutation();

  const handleProcessPayout = async () => {
    setProcessing(true);
    try {
      const payload =
        data.type === "event"
          ? {
              eventId: data.eventId,
              type: "event",
              ticketIds: data.tickets.flatMap((t) => t.soldTicketIds),
            }
          : {
              type: "booking",
              bookingId: data.bookingId,
            };
      await processPayout(payload).unwrap();
      onClose();
    } catch (error) {
      console.error("Failed to process payout:", error);
    } finally {
      setProcessing(false);
    }
  };

  if (!data) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Payout Details</DialogTitle>
      {data.warning && (
        <Alert sx={{ borderRadius: 0 }} severity="warning">
          This payout includes future dates. Please verify before processing.
        </Alert>
      )}
      <DialogContent>
        <Stack spacing={3}>
          {profileLoading ? (
            <CircularProgress />
          ) : (
            <ProfileInfo profile={profile} />
          )}

          <Divider />

          {data.type === "event" ? (
            <>
              <Box>
                <Typography variant="h6">Event Details</Typography>
                <Typography>{data.eventName}</Typography>
                <Typography color="text.secondary">
                  {format(new Date(data.eventDate), "PPP")}
                </Typography>
              </Box>

              <Box>
                <Typography variant="h6">Tickets Summary</Typography>
                <TableContainer component={Paper} variant="outlined">
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Ticket Type</TableCell>
                        <TableCell align="right">Quantity</TableCell>
                        <TableCell align="right">Amount</TableCell>
                        <TableCell align="right">Commission</TableCell>
                        <TableCell align="right">Net</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.tickets.map((ticket) => (
                        <TableRow key={ticket.ticketId}>
                          <TableCell>{ticket.ticketName}</TableCell>
                          <TableCell align="right">
                            {ticket.ticketsSold}
                          </TableCell>
                          <TableCell align="right">
                            ${ticket.totalAmount.toFixed(2)}
                          </TableCell>
                          <TableCell align="right">
                            ${ticket.totalCommission.toFixed(2)}
                          </TableCell>
                          <TableCell align="right">
                            ${ticket.netAmount.toFixed(2)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </>
          ) : (
            <Box>
              <Typography variant="h6">Booking Details</Typography>
              <Typography>Booking: {data.bookingNumber}</Typography>
              <Typography>Service: {data.listingTitle}</Typography>
              <Typography>Client: {data.clientName}</Typography>
              <Typography color="text.secondary">
                Dates:{" "}
                {data.acceptedDates
                  .map((date) => format(new Date(date), "PP"))
                  .join(", ")}
              </Typography>
            </Box>
          )}
          {data.type === "event" ? (
            <>
              <Box>
                <Typography variant="h6">Summary</Typography>
                <Typography>
                  Total Amount: ${data.summary?.totalAmount?.toFixed(2)}
                </Typography>
                <Typography>
                  Commission: ${data.summary?.totalCommission?.toFixed(2)}
                </Typography>
                <Typography variant="h6" color="primary">
                  Net Payout: ${data.summary?.netAmount?.toFixed(2)}
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Box>
                <Typography variant="h6">Summary</Typography>
                <Typography>
                  Total Amount: ${data.amount?.toFixed(2)}
                </Typography>
                <Typography>
                  Commission: ${data.commission?.toFixed(2)}
                </Typography>
                <Typography variant="h6" color="primary">
                  Net Payout: ${data.netAmount?.toFixed(2)}
                </Typography>
              </Box>
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ textTransform: "none", borderRadius: 32 }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          sx={{ textTransform: "none", borderRadius: 32 }}
          onClick={handleProcessPayout}
          variant="contained"
          disabled={processing || profile?.verificationStatus !== "Verified"}
        >
          {processing ? <CircularProgress size={24} /> : "Mark as Processed"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:hover": {
    cursor: "pointer",
    backgroundColor: theme.palette.action.selected,
  },
}));

const MobilePayoutCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const InfoRow = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(1),
}));

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: "0.875rem",
}));

const Value = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
}));

const PayoutList = ({ payouts, onPayoutClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (isMobile) {
    return (
      <Stack spacing={2}>
        {payouts.map((payout, index) => (
          <MobilePayoutCard
            key={index}
            onClick={() => onPayoutClick(payout)}
            variant="outlined"
          >
            <CardContent>
              <Stack spacing={1}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Chip
                    label={payout.type === "event" ? "Event" : "Booking"}
                    color={payout.type === "event" ? "primary" : "secondary"}
                    size="small"
                  />
                  {payout.warning && (
                    <Chip label="Future" color="warning" size="small" />
                  )}
                </Box>

                <InfoRow>
                  <Label>Date</Label>
                  <Value>{format(payout.date, "PP")}</Value>
                </InfoRow>

                <InfoRow>
                  <Label>Provider</Label>
                  <Value>{payout.profileName}</Value>
                </InfoRow>

                <InfoRow>
                  <Label>{payout.type === "event" ? "Event" : "Service"}</Label>
                  <Value>{payout.description}</Value>
                </InfoRow>

                <InfoRow>
                  <Label>Reference</Label>
                  <Value>{payout.reference}</Value>
                </InfoRow>

                <Divider />

                <InfoRow>
                  <Label>Amount</Label>
                  <Value>${payout.amount.toFixed(2)}</Value>
                </InfoRow>

                <InfoRow>
                  <Label>Commission</Label>
                  <Value>${payout.commission.toFixed(2)}</Value>
                </InfoRow>

                <InfoRow>
                  <Label>Net Amount</Label>
                  <Value color="primary">${payout.netAmount.toFixed(2)}</Value>
                </InfoRow>
              </Stack>
            </CardContent>
          </MobilePayoutCard>
        ))}
      </Stack>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Provider</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Reference</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Commission</TableCell>
            <TableCell align="right">Net Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payouts.map((payout, index) => (
            <StyledTableRow key={index} onClick={() => onPayoutClick(payout)}>
              <TableCell>{format(payout.date, "PP")}</TableCell>
              <TableCell>
                <Chip
                  label={payout.type === "event" ? "Event" : "Booking"}
                  color={payout.type === "event" ? "primary" : "secondary"}
                  size="small"
                />
              </TableCell>
              <TableCell>{payout.profileName}</TableCell>
              <TableCell>{payout.description}</TableCell>
              <TableCell>{payout.reference}</TableCell>
              <TableCell align="right">${payout.amount.toFixed(2)}</TableCell>
              <TableCell align="right">
                ${payout.commission.toFixed(2)}
              </TableCell>
              <TableCell align="right">
                ${payout.netAmount.toFixed(2)}
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default function Payouts() {
  const { data, isLoading, error } = useGetPayoutSummariesQuery();
  const [selectedPayout, setSelectedPayout] = useState(null);

  // Merge and sort events and bookings by date
  const allPayouts = React.useMemo(() => {
    return [
      ...(data?.events.map((event) => ({
        type: "event",
        date: new Date(event.eventDate),
        profileName: event.profileName,
        description: event.eventName,
        reference: `Event`,
        amount: event.summary.totalAmount,
        commission: event.summary.totalCommission,
        netAmount: event.summary.netAmount,
        warning: event.warning,
        originalData: event,
      })) ?? []),
      ...(data?.bookings.map((booking) => ({
        type: "booking",
        date: new Date(booking.date),
        profileName: booking.profileName,
        description: booking.listingTitle,
        reference: `Booking #${booking.bookingNumber}`,
        amount: booking.amount,
        commission: booking.commission,
        netAmount: booking.netAmount,
        warning: booking.warning,
        originalData: booking,
      })) ?? []),
    ].sort((a, b) => b.date - a.date);
  }, [data]);

  const handleOpenPayout = (item) => {
    setSelectedPayout({
      type: item.type,
      ...item.originalData,
    });
  };

  const handleClosePayout = () => {
    setSelectedPayout(null);
  };

  return (
    <Stack
      sx={{
        pl: { xs: 2, sm: 2, md: 3, lg: 3 },
        mt: 2,
        pr: { xs: 2, sm: 2, md: 3, lg: 3 },
        height: "100vh",
        overflow: "scroll",
        pb: 16,
      }}
      spacing={3}
    >
      <Stack
        direction={{ xs: "column", sm: "row", md: "row", lg: "row" }}
        sx={{
          backgroundColor: "#121212",
          borderRadius: 2,
          p: 2,
          justifyContent: "space-between",
          alignItems: "center",
          py: { xs: 1, sm: 1, md: 2, lg: 2 },
        }}
      >
        <Stack
          sx={{
            display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
          }}
        >
          <h2 style={{ margin: 0 }}>Pending Payouts</h2>
        </Stack>
      </Stack>
      {isLoading && <CircularProgress />}
      {error && <Alert severity="error">Failed to load payouts</Alert>}

      <PayoutList payouts={allPayouts} onPayoutClick={handleOpenPayout} />

      <PayoutModal
        open={!!selectedPayout}
        onClose={handleClosePayout}
        data={selectedPayout}
      />
    </Stack>
  );
}
