import { Stack, Button, InputLabel, Input } from "@mui/material";
import * as React from "react";
import Box from "@mui/material/Box";
import TableHeader from "./table/header";
import Grow from "@mui/material/Grow";
import Row from "./table/row";

import { IconButton } from "@mui/material";
import { IoMdRefresh } from "react-icons/io";
import Typography from "@mui/material/Typography";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { useGetProfilesQuery } from "../../api/endpoints/profile";

const sortProfiles = (profiles) => {
  return [...profiles].sort((a, b) => {
    // Helper function to extract display name based on profile type
    const getDisplayName = (profile) => {
      switch (profile.type) {
        case "personal":
          return profile.personal?.firstName && profile.personal?.lastName
            ? `${profile.personal.firstName} ${profile.personal.lastName}`
            : "";

        case "entertainer":
          return profile.entertainer?.entertainerType === "individual"
            ? `${profile.entertainer.individual?.firstName || ""} ${
                profile.entertainer.individual?.lastName || ""
              }`
            : profile.entertainer?.business?.businessName || "";

        case "venue":
          return profile.venue?.venueName || "";

        case "corporate":
          return profile.corporate?.businessName || "";

        case "event_promoter":
          return profile.eventPromoter?.promoterType === "individual"
            ? `${profile.eventPromoter.individual?.firstName || ""} ${
                profile.eventPromoter.individual?.lastName || ""
              }`
            : profile.eventPromoter?.business?.businessName || "";

        default:
          return "";
      }
    };

    const nameA = getDisplayName(a).trim().toLowerCase();
    const nameB = getDisplayName(b).trim().toLowerCase();

    return nameA.localeCompare(nameB);
  });
};

export default function Users() {
  const {
    data: users = [],
    isLoading,
    isFetching,
    refetch,
  } = useGetProfilesQuery();

  const [type, setType] = React.useState("verified");

  const filteredUsers = React.useMemo(() => {
    return users.filter((profile) => {
      if (type === "verified") {
        return (
          profile.verificationStatus === "Verified" ||
          (profile.type === "personal" &&
            profile.verificationStatus === "Not Applicable")
        );
      } else {
        return (
          profile.verificationStatus === "Pending" &&
          profile.requiresVerification
        );
      }
    });
  }, [users, type]);

  const sortedUsers = sortProfiles(filteredUsers); // Use the existing sortProfiles function

  const checked = true;

  return (
    <Grow in={checked}>
      <Box
        sx={{
          ml: {
            xs: 2,
            sm: 2,
            md: 3,
            lg: 3,
          },
          mt: 2,
          mr: {
            xs: 2,
            sm: 2,
            md: 3,
            lg: 3,
          },
          height: "100vh",
          pb: 16,
        }}
      >
        <Stack
          direction="row"
          sx={{
            backgroundColor: "#121212",
            borderRadius: 2,
            p: 2,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack>
            <h2 style={{ margin: 0 }}>User profiles</h2>
          </Stack>
          <RadioGroup
            defaultValue={type}
            value={type}
            onChange={(e) => {
              setType(e.target.value);
              refetch();
            }}
          >
            <Stack sx={{ alignItems: "center" }} direction="row">
              <InputLabel sx={{ cursor: "pointer" }}>
                <Radio color="info" value="verified" />
                <Typography variant="caption">Verified</Typography>
              </InputLabel>
              <InputLabel sx={{ cursor: "pointer", ml: 1 }}>
                <Radio color="info" value="pending" />
                <Typography variant="caption">Pending</Typography>
              </InputLabel>
              <IconButton
                disabled={isFetching || isLoading}
                sx={{ ml: 2 }}
                onClick={refetch}
              >
                <IoMdRefresh />
              </IconButton>
            </Stack>
          </RadioGroup>
        </Stack>
        <Stack>
          <TableHeader loading={isFetching || isLoading} />
          <Stack sx={{ height: "78vh", overflow: "scroll", pb: 4 }}>
            {sortedUsers.map((item, index) => {
              return (
                <Row
                  profile={item}
                  index={index}
                  length={sortedUsers.length}
                  key={item._id}
                />
              );
            })}
          </Stack>
        </Stack>
      </Box>
    </Grow>
  );
}
