import { apiSlice } from "../api.service";

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query({
      query: (params) => ({
        url: "/admin/events",
        method: "GET",
        params: {
          limit: params?.limit || 10,
          skip: params?.skip || 0,
          approvalStatus: params?.approvalStatus,
        },
      }),
      transformResponse: (response) => {
        if (Array.isArray(response)) {
          return { data: response, total: response.length };
        }
        return response;
      },
      providesTags: (result = { data: [] }) => [
        ...result.data.map(({ _id }) => ({ type: "Event-Admin", id: _id })),
        { type: "Event-Admin", id: "LIST" },
      ],
    }),

    getEvent: builder.query({
      query: (eventId) => ({
        url: `/admin/events/${eventId}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [{ type: "Event-Admin", id: arg }],
    }),

    processEvent: builder.mutation({
      query: ({ eventId, action }) => ({
        url: `/admin/events/${eventId}/process`,
        method: "PATCH",
        body: { action },
      }),
      invalidatesTags: (result, error, { eventId }) => [
        { type: "Event-Admin", id: eventId },
        { type: "Event-Admin", id: "LIST" },
      ],
      async onQueryStarted({ eventId }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedEvent } = await queryFulfilled;

          // Update individual event cache
          dispatch(
            apiSlice.util.updateQueryData("getEvent", eventId, (draft) => {
              Object.assign(draft, updatedEvent);
            })
          );

          // Update event in list cache
          dispatch(
            apiSlice.util.updateQueryData("getEvents", undefined, (draft) => {
              const event = draft.data?.find((e) => e._id === eventId);
              if (event) {
                Object.assign(event, updatedEvent);
              }
            })
          );
        } catch (err) {
          console.error("Error processing event:", err);
        }
      },
    }),

    deleteEvent: builder.mutation({
      query: (eventId) => ({
        url: `/admin/events/${eventId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, eventId) => [
        { type: "Event-Admin", id: eventId },
        { type: "Event-Admin", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetEventsQuery,
  useGetEventQuery,
  useProcessEventMutation,
  useDeleteEventMutation,
} = extendedApi;
