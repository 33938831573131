import Stack from "@mui/material/Stack";
import * as React from "react";
import Typography from "@mui/material/Typography";
import TableHeader from "./table/header";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { useGetBookingsQuery } from "../../api/endpoints/booking";
import Row from "./table/row";
import Booking from "./table/modal/view";
import { FormControlLabel, IconButton, TablePagination } from "@mui/material";
import { IoMdRefresh } from "react-icons/io";

export default function AdminBookings() {
  const [type, setType] = React.useState("unpaid");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const {
    data = { data: [], total: 0 },
    isLoading,
    refetch,
    isFetching,
  } = useGetBookingsQuery({
    limit: rowsPerPage,
    skip: page * rowsPerPage,
    type: type,
  });

  const [open, setOpen] = React.useState(false);
  const [bookingId, setBookingId] = React.useState(null);

  const handleChangePage = React.useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = React.useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  return (
    <Stack
      sx={{
        ml: { xs: 2, sm: 2, md: 3, lg: 3 },
        mt: 2,
        mr: { xs: 2, sm: 2, md: 3, lg: 3 },
        flex: 1,
      }}
    >
      <Stack
        direction={{ xs: "column", sm: "row", md: "row", lg: "row" }}
        sx={{
          backgroundColor: "#121212",
          borderRadius: 2,
          p: 2,
          justifyContent: "space-between",
          alignItems: "center",
          py: { xs: 1, sm: 1, md: 2, lg: 2 },
        }}
      >
        <Stack
          sx={{
            display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
          }}
        >
          <h2 style={{ margin: 0 }}>Booking Management</h2>
        </Stack>
        <RadioGroup
          value={type}
          onChange={(e) => {
            setType(e.target.value);
            setPage(0); // Reset page when type changes
            refetch();
          }}
        >
          <Stack spacing={1} sx={{ alignItems: "center" }} direction="row">
            <FormControlLabel
              sx={{ cursor: "pointer" }}
              label={<Typography variant="caption">Unpaid</Typography>}
              control={<Radio color="info" value="unpaid" />}
            />

            <FormControlLabel
              sx={{ cursor: "pointer" }}
              label={<Typography variant="caption">Paid</Typography>}
              control={<Radio color="info" value="paid" />}
            />

            <FormControlLabel
              sx={{ cursor: "pointer" }}
              label={<Typography variant="caption">Complete</Typography>}
              control={<Radio color="info" sx={{ ml: 2 }} value="complete" />}
            />

            <IconButton disabled={isFetching || isLoading} onClick={refetch}>
              <IoMdRefresh />
            </IconButton>
          </Stack>
        </RadioGroup>
        <TablePagination
          component="div"
          count={data.total}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50]}
          sx={{
            ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows, .MuiTablePagination-select, .MuiTablePagination-actions":
              {
                color: "white",
                svg: { color: "white" },
              },
          }}
        />
      </Stack>
      <Stack>
        <TableHeader type={type} loading={isFetching || isLoading} />
        <Stack sx={{ height: "78vh", overflow: "scroll", pb: 4 }}>
          {data?.data?.map((booking, index) => {
            return (
              <Row
                type={type}
                setOpen={setOpen}
                setBookingId={setBookingId}
                booking={booking}
                index={index}
                length={data.data.length}
                key={booking._id}
              />
            );
          })}
        </Stack>
      </Stack>

      {open && <Booking id={bookingId} open={open} setOpen={setOpen} />}
    </Stack>
  );
}
