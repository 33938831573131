import Stack from "@mui/material/Stack";
import * as React from "react";
import Typography from "@mui/material/Typography";
import TableHeader from "./table/header";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Grow from "@mui/material/Grow";
import { useGetListingsQuery } from "../../api/endpoints/listing";
import Row from "./table/row";
import { FormControlLabel, IconButton, TablePagination } from "@mui/material";
import { IoMdRefresh } from "react-icons/io";

export default function Listings() {
  const [approvalStatus, setApprovalStatus] = React.useState("pending");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const {
    data = { data: [], total: 0 },
    isLoading,
    refetch,
    isFetching,
  } = useGetListingsQuery({
    limit: rowsPerPage,
    skip: page * rowsPerPage,
    approvalStatus: approvalStatus,
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Stack
      sx={{
        pl: { xs: 2, sm: 2, md: 3, lg: 3 },
        mt: 2,
        pr: { xs: 2, sm: 2, md: 3, lg: 3 },
        height: "100vh",
        overflow: "scroll",
        pb: 16,
      }}
    >
      <Stack
        direction={{ xs: "column", sm: "row", md: "row", lg: "row" }}
        sx={{
          backgroundColor: "#121212",
          borderRadius: 2,
          p: 2,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack
          sx={{
            display: { xs: "none", sm: "none", md: "flex" },
          }}
        >
          <h2 style={{ margin: 0 }}>Listings</h2>
        </Stack>

        <Stack direction="row" spacing={2} alignItems="center">
          <RadioGroup
            row
            value={approvalStatus}
            onChange={(e) => {
              setApprovalStatus(e.target.value);
              setPage(0);
            }}
          >
            <FormControlLabel
              value="pending"
              control={<Radio color="info" />}
              label={
                <Typography variant="caption" sx={{ ml: 1 }}>
                  Pending
                </Typography>
              }
            />
            <FormControlLabel
              value="approved"
              control={<Radio color="info" />}
              label={
                <Typography variant="caption" sx={{ ml: 1 }}>
                  Approved
                </Typography>
              }
            />
            <FormControlLabel
              value="rejected"
              control={<Radio color="info" />}
              label={
                <Typography variant="caption" sx={{ ml: 1 }}>
                  Rejected
                </Typography>
              }
            />
          </RadioGroup>

          <IconButton
            disabled={isFetching || isLoading}
            onClick={() => refetch()}
            size="small"
          >
            <IoMdRefresh />
          </IconButton>
        </Stack>

        <TablePagination
          component="div"
          count={data.total}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          sx={{
            ".MuiTablePagination-select": {
              color: "white",
            },
            ".MuiTablePagination-selectLabel": {
              color: "white",
            },
            ".MuiTablePagination-displayedRows": {
              color: "white",
            },
          }}
        />
      </Stack>

      <Stack>
        <TableHeader loading={isFetching || isLoading} />

        <Stack sx={{ height: "78vh", overflow: "scroll", pb: 4 }}>
          {data.data.map((listing, index) => (
            <Row listing={listing} index={index} length={data.data.length} />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
}
