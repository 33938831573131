import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { constants } from "../../../../config/constants";
import { useProcessBookingPopMutation } from "../../../../api/endpoints/booking";
import "react-medium-image-zoom/dist/styles.css";
import Zoom from "react-medium-image-zoom";
import { Button, Chip, CircularProgress } from "@mui/material";

export default function ProofOfPayment({ booking }) {
  const [processBookingPop, { isLoading }] = useProcessBookingPopMutation();

  const updateBooking = async (approved) => {
    try {
      await processBookingPop({
        bookingId: booking._id,
        approved,
      });
    } catch (e) {
      alert("Error processing POP: " + e.message);
    }
  };

  return (
    <Stack sx={{ mt: 2 }}>
      <Stack sx={{ background: "#353535", p: 2, borderRadius: 2 }}>
        <Stack>
          <Typography sx={{ mb: 1 }}>Proof Of Payment</Typography>
          <Zoom>
            <img
              alt="pop"
              style={{
                width: "100%",
                objectFit: "cover",
                borderRadius: 16,
                height: 220,
              }}
              src={`${constants.apiUrl}/files/${booking.proofOfPayment.documentId}`}
            />
          </Zoom>

          {booking.proofOfPayment.approved && (
            <Chip
              color="success"
              sx={{ alignSelf: "flex-start" }}
              label="Approved"
            />
          )}

          {isLoading && <CircularProgress size={16} sx={{ m: 1 }} />}

          {!booking.proofOfPayment.approved &&
            !booking.proofOfPayment.rejected && (
              <Stack direction="row" spacing={2}>
                <Button
                  onClick={() => updateBooking(true)}
                  disabled={isLoading}
                  color="success"
                >
                  Approve
                </Button>
                <Button
                  onClick={() => updateBooking(false)}
                  disabled={isLoading}
                  color="error"
                >
                  Reject
                </Button>
              </Stack>
            )}
        </Stack>
      </Stack>
    </Stack>
  );
}
