import Stack from "@mui/material/Stack";
import * as React from "react";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import Booking from "./modal/view";

export default function Row({ profile, index, length }) {
  const checked = true;
  const [open, setOpen] = React.useState(false);

  const accountName = React.useMemo(() => {
    switch (profile?.type) {
      case "personal":
        return profile.personal?.firstName && profile.personal?.lastName
          ? `${profile.personal.firstName} ${profile.personal.lastName}`
          : "";

      case "entertainer":
        return profile.entertainer?.entertainerType === "individual"
          ? `${profile.entertainer.individual?.firstName || ""} ${
              profile.entertainer.individual?.lastName || ""
            }`
          : profile.entertainer?.business?.businessName || "";

      case "venue":
        return profile.venue?.venueName || "";

      case "corporate":
        return profile.corporate?.businessName || "";

      case "event_promoter":
        return profile.eventPromoter?.promoterType === "individual"
          ? `${profile.eventPromoter.individual?.firstName || ""} ${
              profile.eventPromoter.individual?.lastName || ""
            }`
          : profile.eventPromoter?.business?.businessName || "";

      default:
        return "";
    }
  }, [profile]);

  const accountType = React.useMemo(() => {
    // Capitalize first letter and convert underscores to spaces
    return profile?.type
      ? profile?.type.charAt(0).toUpperCase() +
          profile?.type.slice(1).toLowerCase().replace("_", " ")
      : "";
  }, [profile?.type]);

  return (
    <>
      <Fade timeout={2000} in={checked}>
        <Stack
          sx={{
            borderRadius: 0,
            p: 2,
            mt: 1,
            background: "#1B1B1B",
            borderWidth: 0,
            borderLeftWidth: 1,
            borderStyle: "solid",
            alignItems: "center",
            flex: 1,
          }}
          direction="row"
        >
          <Stack sx={{ flex: 1, pr: 2 }}>
            <Typography
              onClick={() => {
                setOpen(true);
              }}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  textDecorationLine: "underline",
                },
              }}
            >
              {accountName}
            </Typography>
            <Typography variant="caption">
              {index + 1} of {length}
            </Typography>
          </Stack>
          <Typography sx={{ flex: 1, pr: 2 }}>
            <b>{accountType}</b>
          </Typography>
          <Typography sx={{ flex: 1, pr: 2 }}>
            {profile.phone || " - "}
          </Typography>
          <Typography
            sx={{
              flex: 1,
              pr: 2,
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                lg: "flex",
              },
            }}
          >
            {profile.user?.email}
          </Typography>
        </Stack>
      </Fade>
      {open && <Booking open={open} id={profile._id} setOpen={setOpen} />}
    </>
  );
}
