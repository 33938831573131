import Stack from "@mui/material/Stack";
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grow from "@mui/material/Grow";
import {
  TextField,
  InputAdornment,
  Button,
  LinearProgress,
  Alert,
  Snackbar,
} from "@mui/material";
import {
  useGetConfigurationQuery,
  useUpdateConfigurationMutation,
} from "../../api/endpoints/configuration"; // Update path as needed

export default function Settings() {
  const checked = true;
  const [successMessage, setSuccessMessage] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  // Get configuration data
  const { data: config, isLoading: isLoadingConfig } =
    useGetConfigurationQuery();

  // Update configuration mutation
  const [updateConfiguration, { isLoading: isUpdating }] =
    useUpdateConfigurationMutation();

  const [values, setValues] = React.useState({
    commission: "",
    ratePerKm: "",
    ticketCommission: "",
  });

  // Update local state when config data is loaded
  React.useEffect(() => {
    if (config) {
      setValues({
        commission: config.commission,
        ratePerKm: config.ratePerKm,
        ticketCommission: config.ticketCommission,
      });
    }
  }, [config]);

  const saveChanges = async () => {
    try {
      await updateConfiguration({
        commission: Number(values.commission),
        ratePerKm: Number(values.ratePerKm),
        ticketCommission: Number(values.ticketCommission),
      }).unwrap();
      setSuccessMessage("Changes saved successfully!");
    } catch (error) {
      setErrorMessage("Error saving changes. Please try again.");
    }
  };

  const isLoading = isLoadingConfig || isUpdating;

  const handleCloseSnackbar = () => {
    setSuccessMessage("");
    setErrorMessage("");
  };

  return (
    <Grow in={checked}>
      <Box
        sx={{
          ml: 4,
          mt: 2,
          mr: 2,
          height: "100vh",
          overflow: "scroll",
          pb: 16,
        }}
      >
        <Stack
          sx={{
            backgroundColor: "#121212",
            borderRadius: 2,
            p: 2,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2 style={{ margin: 0 }}>
            Edit <span style={{ color: "#6F91C8" }}>Platform Rates</span>
          </h2>
        </Stack>
        {isLoading && <LinearProgress sx={{ borderRadius: 2 }} />}
        <Stack
          direction={{
            xs: "column",
            sm: "row",
            md: "row",
            lg: "row",
          }}
          spacing={2}
          sx={{ mt: 2 }}
        >
          <Stack
            sx={{
              p: {
                xs: 2,
                sm: 2,
                md: 4,
              },
              background: "#202020",
              borderRadius: 2,
              flex: 1,
            }}
          >
            <Typography>Commission (Bookings)</Typography>
            <Stack sx={{ mt: 2 }} direction="row">
              <TextField
                value={values.commission}
                onChange={(e) => {
                  setValues((prev) => ({
                    ...prev,
                    commission: e.target.value,
                  }));
                }}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                sx={{ flex: 1, mr: 2, maxWidth: 300 }}
                placeholder="Edit commission"
              />
            </Stack>
          </Stack>
          <Stack
            sx={{
              p: {
                xs: 2,
                sm: 2,
                md: 4,
              },
              background: "#202020",
              borderRadius: 2,
              flex: 1,
            }}
          >
            <Typography>Commission (Tickets)</Typography>
            <Stack sx={{ mt: 2 }} direction="row">
              <TextField
                value={values.ticketCommission}
                onChange={(e) => {
                  setValues((prev) => ({
                    ...prev,
                    ticketCommission: e.target.value,
                  }));
                }}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                sx={{ flex: 1, mr: 2, maxWidth: 300 }}
                placeholder="Edit commission"
              />
            </Stack>
          </Stack>
          <Stack
            sx={{
              p: {
                xs: 2,
                sm: 2,
                md: 4,
              },
              background: "#202020",
              borderRadius: 2,
              flex: 1,
            }}
          >
            <Typography>Rate per KM</Typography>
            <Stack sx={{ mt: 2 }} direction="row">
              <TextField
                value={values.ratePerKm}
                onChange={(e) => {
                  setValues((prev) => ({
                    ...prev,
                    ratePerKm: e.target.value,
                  }));
                }}
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                sx={{ flex: 1, mr: 2, maxWidth: 300 }}
                placeholder="Edit rate per km"
              />
            </Stack>
          </Stack>
        </Stack>
        <Button
          disabled={
            !values.ratePerKm ||
            !values.commission ||
            !values.ticketCommission ||
            isLoading
          }
          onClick={saveChanges}
          sx={{
            textTransform: "none",
            mt: 4,
            height: 50,
          }}
          variant="contained"
        >
          Save Changes
        </Button>

        <Snackbar
          open={!!successMessage || !!errorMessage}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={successMessage ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {successMessage || errorMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Grow>
  );
}
