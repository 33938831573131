import {
  HiCalendar,
  HiBanknotes,
  HiSparkles,
  HiWrench,
  HiUser,
} from "react-icons/hi2";

import { MdPiano } from "react-icons/md";

const iconSize = 20;

const options = [
  {
    id: "",
    title: "Bookings",
    icon: <HiCalendar size={iconSize} />,
  },
  {
    id: "profiles",
    title: "Profiles",
    icon: <HiUser size={iconSize} />,
  },
  {
    id: "listings",
    title: "Listings",
    icon: <MdPiano size={iconSize} />,
  },
  {
    id: "events",
    title: "Events",
    icon: <HiSparkles size={iconSize} />,
  },

  {
    id: "payouts",
    title: "Payouts",
    icon: <HiBanknotes size={iconSize} />,
  },

  {
    id: "settings",
    title: "Settings",
    icon: <HiWrench size={iconSize} />,
  },
];

export { options };
