import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
} from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import Listings from "./pages/listings/view";
import Events from "./pages/events/view";
import ErrorPage from "./pages/error-page";
import Root from "./layouts/root/view";
import Bookings from "./pages/bookings/view";
import Charges from "./pages/charges/view";
import Settings from "./pages/settings/view";
import Users from "./pages/users/view";
import Payouts from "./pages/payouts/view";
import Verifications from "./pages/verifications/view";
import Signin from "./pages/sign-in/view";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./redux/store/store";
import { store } from "./redux/store/store";

import "./index.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import User from "./redux/slices/user";

const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

const App = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route errorElement={<ErrorPage />}>
        <Route path="/" element={<Root />}>
          <Route index element={<Bookings />} />
          <Route path="payouts" element={<Payouts />} />
          <Route path="profiles" element={<Users />} />
          <Route path="charges" element={<Charges />} />
          <Route path="verifications" element={<Verifications />} />
          <Route path="listings" element={<Listings />} />
          <Route path="settings" element={<Settings />} />

          <Route path="events" element={<Events />} />
          <Route path="*" element={<div />} />
        </Route>
        <Route path="signin" element={<Signin />}></Route>
      </Route>
    )
  );

  //
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <PersistGate
          loading={
            <div>
              <p>Loading...</p>
            </div>
          }
          persistor={persistor}
        >
          <RouterProvider router={router} />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
